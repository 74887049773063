import * as types from '../../types';
import intersection from 'lodash.intersection';
import difference from 'lodash.difference';

const initialData = {
  macroeconomicVariables: {},
  columns: {
    macrofactors: {
      id: 'macrofactors',
      title: 'Macro Factors',
      arrayOfIDs: [],
    },
    'category-1': {
      id: 'category-1',
      title: 'Category 1',
      arrayOfIDs: [],
    },
    'category-2': {
      id: 'category-2',
      title: 'Category 2',
      arrayOfIDs: [],
    },
    'category-3': {
      id: 'category-3',
      title: 'Category 3',
      arrayOfIDs: [],
    },
    'category-4': {
      id: 'category-4',
      title: 'Category 4',
      arrayOfIDs: [],
    },
    'category-5': {
      id: 'category-5',
      title: 'Category 5',
      arrayOfIDs: [],
    },
    'category-6': {
      id: 'category-6',
      title: 'Category 6',
      arrayOfIDs: [],
    },
    'category-7': {
      id: 'category-7',
      title: 'Category 7',
      arrayOfIDs: [],
    },
    'category-8': {
      id: 'category-8',
      title: 'Category 8',
      arrayOfIDs: [],
    },
  },
  // Facilitate reordering of the columns
  columnOrder: [
    'macrofactors',
    'category-1',
    'category-2',
    'category-3',
    'category-4',
    'category-5',
    'category-6',
    'category-7',
    'category-8',
  ],
};

const initialEstimatorModelState = {
  estimatorRules: { simulationBlocks: true, drawsPerBlock: true, adjustmentSpeed: true },
  estimatorData: {
    subsetRegressionData: initialData,
    coreModelSpecification: null,
    modelEstimationStrategy: null,
    sigmaAlpha: '',
    simulationBlocks: '',
    drawsPerBlock: '',
    adjustmentSpeed: '',
    rollingWindow: false,
    vintageWindowLength: null,
    customizeVintageCalendar: false,
    vintageMonthEndDay: null,
    enableCompleteSubsetRegression: false,
    variableCount: null,
    gammaCSR: null,
    // simulated
    modelWeightRuleSimulatedModels: null,
    weightingSensitivity: null,
  },
  isSimulatedModelCombination: false,
  isSegmentedModelCombination: false,
  simulatedModelData: {
    selectedModels: [],
  },
  segmentedModelData: {
    selectedModels: [],
  },
};

const estimatorModelReducer = (state = initialEstimatorModelState, action) => {
  switch (action.type) {
    case types.USER_LOGOUT:
      return initialEstimatorModelState;
    case types.ESTIMATOR_MODEL_SET_RULES:
      return {
        ...state,
        estimatorRules: action.payload,
      };
    case types.ESTIMATOR_MODEL_LOAD_DATA:
      return {
        ...state,
        isSimulatedModelCombination: false,
        isSegmentedModelCombination: false,
        simulatedModelData: {
          selectedModels: [],
        },
        estimatorData: {
          ...state.estimatorData,
          ...action.payload,
          // simulated
          modelWeightRuleSimulatedModels: null,
          weightingSensitivity: null,
        },
      };

    case types.ESTIMATOR_MODEL_SMC_LOAD_DATA: {
      const { selectedModels, isSimulatedModelCombination, ...rest } = action.payload;
      return {
        ...state,
        isSimulatedModelCombination: action?.payload?.spec?.isSimulatedModelCombination,
        simulatedModelData: {
          selectedModels: action?.payload?.spec?.selectedModels || [],
        },
        estimatorData: {
          ...state.estimatorData,
          ...action.payload,
          modelWeightRuleSimulatedModels:
            action?.payload?.spec?.modelWeightRuleSimulatedModels || null,
          weightingSensitivity: action?.payload?.spec?.weightingSensitivity || null,
        },
      };
    }
    case types.ESTIMATOR_MODEL_SAVE_DATA:
      const { subsetRegressionData, ...rest } = action.payload;
      return {
        ...state,
        estimatorData: {
          ...state.estimatorData,
          ...rest,
        },
      };
    case types.ESTIMATOR_MODEL_SAVE_DATA_IN_STATE:
      return {
        ...state,
        estimatorData: action.payload,
      };

    case types.ESTIMATOR_MODEL_RESET:
      return {
        ...initialEstimatorModelState,
      };

    // TODO: update with form data?
    case types.UPDATE_SUBSET_REGRESSION:
      return {
        ...state,
        estimatorData: {
          ...state.estimatorData,
          subsetRegressionData: action.payload,
        },
      };
    case types.UPDATE_SUBSET_REGRESSION_MACROFACTORS:
      return {
        ...state,
        estimatorData: {
          ...state.estimatorData,
          subsetRegressionData: {
            macroeconomicVariables: action.payload.variables,
            columns: {
              ...initialData.columns,
              macrofactors: {
                id: 'macrofactors',
                title: 'Macrofactors',
                arrayOfIDs: action.payload.macrofactors,
              },
            },
            columnOrder: initialData.columnOrder,
          },
        },
      };
    case types.REFRESH_SUBSET_REGRESSION_MACROFACTORS:
      const arrayOfIDs = action.payload.arrayOfIDs;
      const newColumns = { ...state.estimatorData.subsetRegressionData.columns };
      let categoryArrayOfIDs = [];

      Object.keys(newColumns).map((column) => {
        if (column !== 'macrofactors') {
          const columnArrayOfIDs = newColumns[column]?.arrayOfIDs;
          const newCategoryArrayIDs = intersection(columnArrayOfIDs, arrayOfIDs);

          newColumns[column].arrayOfIDs = newCategoryArrayIDs;
          categoryArrayOfIDs.push(...newCategoryArrayIDs);
        }
        return newColumns[column];
      });

      newColumns['macrofactors'].arrayOfIDs = difference(arrayOfIDs, categoryArrayOfIDs);

      return {
        ...state,
        estimatorData: {
          ...state.estimatorData,
          subsetRegressionData: {
            ...state.estimatorData.subsetRegressionData,
            macroeconomicVariables: action.payload.variables,
            columns: newColumns,
            columnOrder: initialData.columnOrder,
          },
        },
      };
    case types.ESTIMATOR_MODEL_UPDATE_SELECTED_SIMULATED_MODELS: {
      return {
        ...state,
        simulatedModelData: {
          ...state.simulatedModelData,
          selectedModels: [...action.payload],
        },
      };
    }

    case types.ESTIMATOR_MODEL_IS_SIMULATED_MODEL_COMBINATION: {
      return {
        ...state,
        isSegmentedModelCombination: false,
        isSimulatedModelCombination: action.payload,
      };
    }

    case types.ESTIMATOR_MODEL_IS_SEGMENTED_SIMULATED_MODEL_COMBINATION: {
      return {
        ...state,
        isSimulatedModelCombination: false,
        isSegmentedModelCombination: action.payload,
      };
    }

    case types.ESTIMATOR_MODEL_UPDATE_SELECTED_SEGMENTED_MODELS: {
      return {
        ...state,
        segmentedModelData: {
          ...state.segmentedModelData,
          selectedModels: [...action.payload],
        },
      };
    }

    default:
      return state;
  }
};

export default estimatorModelReducer;
